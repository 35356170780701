import { LeadStatusNumber, LeadStatusNumberDeprecated } from '../enums/lead-status';
import { MatSelectOptions } from '../models/mat-select-options.interface';

export const LeadStatusString: Record<LeadStatusNumber, string> = {
  [LeadStatusNumber.FormRedirect]: 'Form Redirect',
  [LeadStatusNumber.ConfirmationInfo]: 'Confirmation Info',
  [LeadStatusNumber.CustomerVerification]: 'Customer Verification',
  [LeadStatusNumber.PartialSSNValidationFailed]: 'Partial SSN Validation Failed',
  [LeadStatusNumber.IncomeInfo]: 'Income Info',
  [LeadStatusNumber.LightningVerification]: 'Lightning Verification',
  [LeadStatusNumber.EmailConfirmation]: 'Email Confirmation',
  [LeadStatusNumber.BankVerification]: 'Bank Verification',
  [LeadStatusNumber.PlaidConnection]: 'Plaid Connection',
  [LeadStatusNumber.PlaidReportPreparation]: 'Plaid Report Preparation',
  [LeadStatusNumber.ManualOfferCalculation]: 'Manual Offer Calculation',
  [LeadStatusNumber.PlaidReportError]: 'Plaid Report Error',
  [LeadStatusNumber.OfferCalculation]: 'Offer Calculation',
  [LeadStatusNumber.BankAccountConfirmation]: 'Bank Account Confirmation',
  [LeadStatusNumber.RevenueVerification]: 'Revenue Verification',
  [LeadStatusNumber.OfferConfirmation]: 'Offer Confirmation',
  [LeadStatusNumber.Denied]: 'Denied',
  [LeadStatusNumber.ConfirmPersonalDetails]: 'Confirm Personal Details',
  [LeadStatusNumber.AgreementViewed]: 'Agreement Viewed',
  [LeadStatusNumber.Withdrawn]: 'Withdrawn',
  [LeadStatusNumber.DocumentVerification]: 'Document Verification',
  [LeadStatusNumber.AdditionalInfoPending]: 'Additional Info Pending',
  [LeadStatusNumber.PendingReferences]: 'Pending References',
  [LeadStatusNumber.Completed]: 'Completed',
  [LeadStatusNumber.Funded]: 'Funded',
  [LeadStatusNumber.Voided]: 'Voided',
  [LeadStatusNumber.PaidOff]: 'PaidOff',
};

const LeadStatusStringDeprecated: Record<LeadStatusNumberDeprecated, string> = {
  [LeadStatusNumberDeprecated.PersonalDetails]: 'Personal Details',
  [LeadStatusNumberDeprecated.SSNValidationFailed]: 'SSN Validation Failed',
  [LeadStatusNumberDeprecated.FullSsnValidation]: 'SSN Validation',
};

export class ApplicationStatus {
  /**
   * Statuses number list
   */
  static readonly STATUS_LIST = Object.values(LeadStatusNumber)
    .filter((element: number) => !isNaN(element))
    .map((element) => element as number);

  /**
   * Statuses on option list format
   */
  static readonly STATUS_OPTION_LIST: MatSelectOptions[] = this.getStatusesOptionList();

  /**
   * Statuses number list for express application
   */
  static readonly EXPRESS_APPLICATION_STATUS_LIST = [
    LeadStatusNumber.ConfirmationInfo,
    LeadStatusNumber.IncomeInfo,
    LeadStatusNumber.BankVerification,
    LeadStatusNumber.LightningVerification,
    LeadStatusNumber.EmailConfirmation,
  ];

  /**
   * Express application statuses on option list format
   */
  static readonly EXPRESS_APPLICATION_STATUS_OPTION_LIST: MatSelectOptions[] =
    this.getStatusesOptionList(this.EXPRESS_APPLICATION_STATUS_LIST);

  /**
   * Get text for a specific status
   * @param status
   * @returns
   */
  static getStatusText(status: LeadStatusNumber, includeDeprecated: boolean = false): string {
    const statusesList = {
      ...LeadStatusString,
      ...(includeDeprecated ? { ...LeadStatusStringDeprecated } : {}),
    };
    return statusesList[status];
  }

  static getStatusesOptionList(list: Array<number> = this.STATUS_LIST): MatSelectOptions[] {
    return list.map((status) => ({
      value: status,
      text: ApplicationStatus.getStatusText(status),
    }));
  }
}
