import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ApplicationStatus } from 'src/app/classes/application-status';
import { LeadStatusNumber } from 'src/app/enums/lead-status';

@Component({
  selector: 'fo-status-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
})
export class StatusBadgeComponent implements OnInit {
  /**
   * Status id
   */
  @Input() status?: LeadStatusNumber;

  classes: string;
  statusText: string;

  constructor() {}

  ngOnInit(): void {
    this.statusText = ApplicationStatus.getStatusText(this.status, true) || 'Not Defined';
    this.classes = `status-badge--${this.statusText.toLowerCase().replace(/\s/g, '-')}`;
  }
}
