export enum LeadStatusNumber {
  FormRedirect = 50,
  ConfirmationInfo = 100,
  CustomerVerification = 110,
  PartialSSNValidationFailed = 125,
  IncomeInfo = 150,
  LightningVerification = 160,
  EmailConfirmation = 165,
  BankVerification = 400,
  PlaidConnection = 420,
  PlaidReportPreparation = 425,
  ManualOfferCalculation = 450,
  PlaidReportError = 475,
  OfferCalculation = 500,
  BankAccountConfirmation = 520,
  RevenueVerification = 530,
  OfferConfirmation = 550,
  ConfirmPersonalDetails = 610,
  AgreementViewed = 650,
  Denied = 700,
  Withdrawn = 725,
  DocumentVerification = 940,
  AdditionalInfoPending = 950,
  PendingReferences = 960,
  Completed = 970,
  Funded = 1000,
  Voided = 1100,
  PaidOff = 1200,
}

export enum LeadStatusNumberDeprecated {
  PersonalDetails = 170,
  SSNValidationFailed = 175,
  FullSsnValidation = 180,
}

export interface LeadStatusRequest {
  id: number;
  value: string;
  publicId: string;
  language?: string;
  tag?: number;
}

export interface LeadStatusResponse {
  id: number;
  value: string;
  publicId: string;
  language?: string;
  tag?: number;
}
